'use client'

import { useEffect } from 'react'

export const TawkTo = () => {
  useEffect(() => {
    ;(function () {
      const s1 = document.createElement('script')
      s1.async = true
      s1.src = 'https://embed.tawk.to/67070cb5cec6d0125df4545a/1i9poj955'
      s1.charset = 'UTF-8'
      s1.setAttribute('crossorigin', '*')
      const s0 = document.getElementsByTagName('script')[0]
      if (s0 && s0.parentNode) s0.parentNode.insertBefore(s1, s0)
    })()

    window.Tawk_API = window.Tawk_API || {}
    window.Tawk_API.onLoad = function () {
      window.Tawk_API.minimize()
      try {
        const profile = JSON.parse(localStorage.getItem('profile') || '{}')
        if (profile.email) {
          window.Tawk_API.setAttributes(
            {
              name: profile?.name,
              email: profile?.email,
              phone: profile?.phone,
            },
            function (err: Error) {
              console.log('TawkTo attributes set', err)
            },
          )
        }
      } catch (e) {}
    }
  }, [])

  return null
}
